@keyframes floatUp {
    0% {
      transform: translateY(100vh); /* Start from the bottom */
    }
    100% {
      transform: translateY(-200px); /* Move to the top */
    }
  }
  
  .ballon {
    position: absolute;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    animation: floatUp 5s linear forwards; /* Animation for 5 seconds */
  }