::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #012d35;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #9ca3af;
}

body {
  overflow-x: hidden;
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 5rem 1rem 5rem;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  gap: 5px;
  user-select: none;
}

.pagination .page-num {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #012d35;
  color: #fff;
}

.pagination .page-num.active {
  background-color: #012d35;
  color: #fff;
}

.pagination .disabled {
  pointer-events: none;
  color: #ccc;
}
